import React, {useEffect, useRef, useState} from 'react'
import {graphql, Link, useStaticQuery} from 'gatsby'
import {DonateButton} from "./buttons"
import get from 'lodash/get'

import "./navigation.css";

// headerNavigationLink

const NavLinkItem = ({children, paddingRight, setOpen}) => {
  const close = () => setOpen(false)
  return (
    <li className={`flex justify-center items-center hover:text-yellow ${paddingRight ? 'md:pr-5' : ''}`} onClick={close} onKeyDown={close}>
        {children}
    </li>
  )
}

//eslint-disable import/no-anonymous-default-export
export default () => {
  const [open, setOpen] = useState(false)
  const node = useRef()

  const data = useStaticQuery(graphql`
    query HeaderQuery {
      contentfulHeaderNavigation(contentful_id: {eq: "1C6Qlai2d8Oob1U3phEE1L"}) {
        headerLinks {
          headerNavLinkName
          linkType
          linkUrl
        }
      }
    }
  `)

  const navLinks = get(data, 'contentfulHeaderNavigation.headerLinks')
  const filteredLinks = navLinks.filter((node) => node.linkType !== "Donate Button")
  const donate = navLinks.filter((node) => node.linkType === "Donate Button")[0]

  useEffect(() => {
    document.addEventListener('mousedown', handleClose);
    return () => {
      document.removeEventListener('mousedown', handleClose);
    };
    
  }, []);

  const handleClose = (e) => {
    if (node.current && node.current.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    setOpen(false);
  };

  return (
    <header
      ref={node}
      className="min-h-20 bg-gray bg-texture border-b-2 border-gray-200 absolute top-0 z-50 w-full"
    >
      <nav role="navigation" className="w-full flex justify-between items-center h-full flex-flow-wrap px-8 md:px-16" >
        <Link to="/" className="h-20 py-4" onClick={() => setOpen(false)}>
          <img className="h-full" src="/KCEN_logo.png" alt="KCEN logo"/>
        </Link>
        <button className="md:hidden text-white w-8 h-8 relative focus:outline-none" onClick={() => setOpen(!open)}>
          <span className="sr-only">Open main menu</span>
          <div className="block w-8 absolute left-1/2 top-1/2   transform  -translate-x-1/2 -translate-y-1/2">
            <span aria-hidden="true"
                  className={`block absolute h-0.5 w-8 bg-current transform transition duration-500 ease-in-out ${open ? 'rotate-45' : '-translate-y-1.5'}`} />
            <span aria-hidden="true"
                  className={`block absolute h-0.5 w-8 bg-current   transform transition duration-500 ease-in-out ${open ? 'opacity-0' : ''}`} />
            <span aria-hidden="true"
                  className={`block absolute h-0.5 w-8 bg-current transform  transition duration-500 ease-in-out ${open ? '-rotate-45' : 'translate-y-1.5'}`} />
          </div>
        </button>
        <hr className="w-full md:w-0 invisible" />
        <ul className={`transition-all duration-500 ease-in-out font-button overflow-hidden text-2xl md:text-base text-white uppercase flex flex-col md:flex-row justify-around md:justify-end ${!open ? 'invisible md:visible max-h-0' : 'visible max-h-96'} mx-auto md:mx-0 h-1/2-screen md:max-h-20 md:py-0`}>
          <div className="flex flex-col md:flex-row justify-evenly md:justify-end flex-nowrap md:flex-wrap flex-grow md:py-5 md:pl-5 text-center md:text-left	tracking-wide">
          {filteredLinks.map((node, index) => (
            <NavLinkItem setOpen={setOpen} key={index} paddingRight>
              {node.linkType === 'Internal Page' && <Link to={node.linkUrl}>{node.headerNavLinkName}</Link>}
              {node.linkType === 'External Link' &&
                <a href={node.linkUrl} target="_blank" rel="noreferrer">{node.headerNavLinkName}</a>}
            </NavLinkItem>
          ))}
          </div>
          <NavLinkItem setOpen={setOpen}>
            <DonateButton className="bg-yellow px-6 mb-4 md:mb-0" text={donate.headerNavLinkName} />
          </NavLinkItem>
        </ul>
</nav>
</header>
)
}
