//@ts-ignore
import React, { FC } from "react";
import { LGHeader, MarkdownContainer } from "./typography";
import { ButtonNewWindow, DonateButton } from "./buttons";
import {
  ClassNameRequired,
  HeadlineCTASection,
  HeadlineSection,
} from "../utils/common-types";

type HeadlineBlurbSectionProps = HeadlineSection & ClassNameRequired;

export const HeadlineBlurbSection: FC<HeadlineBlurbSectionProps> = ({
  headline,
  blurb,
  className,
}) => (
  <section className={`px-8 py-16 ${className} text-center`}>
    <LGHeader>{headline}</LGHeader>
    <MarkdownContainer className="pt-5 max-w-4xl mx-auto">
      {blurb.childMarkdownRemark.html}
    </MarkdownContainer>
  </section>
);

type HeadlineBlurbCTASectionProps = HeadlineCTASection &
  ClassNameRequired & { useDonateButton?: boolean };

export const HeadlineBlurbCTASection: FC<HeadlineBlurbCTASectionProps> = ({
  headline,
  blurb,
  ctaButtonLink,
  ctaButtonText,
  useDonateButton,
  className,
}) => (
  <section className={`px-8 py-16 ${className} text-center`}>
    <LGHeader>{headline}</LGHeader>
    <MarkdownContainer className="py-5 max-w-4xl mx-auto">
      {blurb.childMarkdownRemark.html}
    </MarkdownContainer>
    {useDonateButton ? (
      <DonateButton
        variant={DonateButton}
        className="border-2 border-white text-white hover:bg-white hover:text-black min-w-button"
        text={ctaButtonText}
      />
    ) : (
      <ButtonNewWindow
        to={ctaButtonLink}
        variant={DonateButton}
        className="border-2 border-white text-white hover:bg-white hover:text-black"
      >
        {ctaButtonText}
      </ButtonNewWindow>
    )}
  </section>
);
