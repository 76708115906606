import React from "react";
import Layout from "../components/layout";
import { graphql } from "gatsby";
import { Link } from "gatsby";
import Img from "gatsby-image";

export default function allBlog({ data }) {
  const blogPosts = data?.allContentfulBlogPost?.edges || [];
  return (
    <Layout location={data}>
      <div className="container mx-auto px-8 my-5">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-x-5 mt-5 w-full">
          {blogPosts.map(({ node }) => (
            <Link to={`/blog/${node.slug}`} key={node.id}>
              <div className="text-center">
                <Img
                  fluid={node.heroImage?.fluid}
                  alt={node.title}
                  className="blog-image w-full h-[120px]"
                  style={{ width: "100%", height: "432px" }}
                />
                <div className="text-center p-2 mt-4">
                  <p className="text-white text-center text-lg uppercase truncate">
                    {node.title}
                  </p>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  {
    contentfulBlogsPage(contentful_id: { eq: "Od5hDeljrmFmi0OXgoaXu" }) {
      blogArchiveTitle
    }
    allContentfulBlogPost(
      filter: { slug: { ne: "richtext-entry-not-visible-do-not-delete" } }
    ) {
      edges {
        node {
          updatedAt
          sys {
            contentType {
              sys {
                id
              }
            }
          }
          title
          slug
          heroImage {
            fluid(maxWidth: 600, maxHeight: 336, resizingBehavior: FILL) {
              ...GatsbyContentfulFluid
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`;
