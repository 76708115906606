import React from "react";
import Layout from "../components/layout";

export default function recentwins({ location }) {
  return (
    <Layout location={location}>
      <div className="py-16 bg-winsbg bg-cover bg-no-repeat">
        <div className="container mx-auto px-5 relative">
          <p className="text-base text-center md:text-left text-white font-bold uppercase">
            Featured recent win
          </p>
          <h1 className="font-heading font-black uppercase text-6xl md:text-8xl text-white w-full text-center md:text-left md:w-1/3">
            Family Empowerment Center{" "}
            <span className="text-yellow-400">$1 million</span>{" "}
            <span className="text-red">~5600 direct actions</span>
          </h1>
          <img
            className="absolute w-28 h-auto right-8 bottom-8 hidden md:flex"
            src="../../KCEN_logo.png"
          />
        </div>
      </div>
      {/* contents */}
      <div className="mt-10">
        <div className="container mx-auto px-8">
          <p className="text-white text-xl font-normal">
            Juneteenth 2024, King County Equity Now celebrated four years of
            steadfast advocacy and significant victories for the Black
            community. We extend heartfelt thanks to YOU, our incredible equity
            champions, for your support in driving an impressive 315,000 actions
            towards advancing equity over the past four years! Despite
            persistent attempts to undermine KCEN’s Juneteenth celebration, the
            event once again triumphed as a resounding success. Featuring
            electrifying by <b>Dead Prez</b>, performances J. <b>Holiday</b>,
            and a multitude of Seattle-based talents, alongside over 100
            vendors, it was a vibrant intergenerational gathering that provided
            space for Black joy and jubilation amidst our ongoing fight for
            justice and equity.
          </p>

          <h1 className="text-yellow-400 text-3xl font-bold mt-10 uppercase">
            KCEN Wins: Moving the Dial for Black Equity
          </h1>
          <p className="text-white text-xl font-normal mt-3">
            Our success is measured by tangible improvements in the lives of
            Black people. Through nearly 315,000 direct actions, KCEN has
            mobilized efforts that have secured:
          </p>

          <div className="mt-10">
            <ul className="text-white text-xl font-normal mx-0 md:mx-72 flex flex-col gap-y-6">
              <li className="flex items-start gap-x-10">
                <img className="w-6 h-auto mt-2" src="../../imgs/liarrow.png" />
                <span>
                  <b>200 million</b> for the Community Reinvestment Program,
                  safeguarded from redirection by opportunistic politicians.
                </span>
              </li>
              <li className="flex items-start gap-x-10">
                <img className="w-6 h-auto mt-2" src="../../imgs/liarrow.png" />
                <span>
                  <b>150 million</b> in increased community development for
                  affordable housing from the Washington State Housing Finance
                  Commission, supporting projects like{" "}
                  <b>
                    Africatown Plaza, Ethiopian Community Center, Elizabeth
                    Thomas Homes, Beacon Pacific Village North Lot, and NP
                    Eastern Hotel.
                  </b>
                </span>
              </li>
              <li className="flex items-start gap-x-10">
                <img className="w-6 h-auto mt-2" src="../../imgs/liarrow.png" />
                <span>
                  <b>14 million</b> allocated for the Keiro project, preventing
                  predatory development and creating opportunities for Black
                  community equity in the Central District.
                </span>
              </li>
              <li className="flex items-start gap-x-10">
                <img className="w-6 h-auto mt-2" src="../../imgs/liarrow.png" />
                <span>
                  The transformation of <b>Fire Station</b> 6 into the William
                  Grose Center, opened in 2022.
                </span>
              </li>
              <li className="flex items-start gap-x-10">
                <img className="w-6 h-auto mt-2" src="../../imgs/liarrow.png" />
                <span>
                  Land transfers for the development of the{" "}
                  <b>Youth Achievement Center</b>, led by Community Passageways
                  & Creative Justice.
                </span>
              </li>
              <li className="flex items-start gap-x-10">
                <img className="w-6 h-auto mt-2" src="../../imgs/liarrow.png" />
                <span>
                  <b>27 million</b> for Africatown Plaza, promoting Black
                  community-centered spaces and scalable models to counter
                  gentrification impacts.
                </span>
              </li>
              <li className="flex items-start gap-x-10">
                <img className="w-6 h-auto mt-2" src="../../imgs/liarrow.png" />
                <span>
                  Secured state funding for the Tubman Center for Health &
                  Freedom, establishing two community-owned health and healing
                  sites in South Seattle, with plans for a 40,000 square-foot
                  center by 2027, serving up to 12,000 patients annually.
                </span>
              </li>
              <li className="flex items-start gap-x-10">
                <img className="w-6 h-auto mt-2" src="../../imgs/liarrow.png" />
                <span>
                  <b>$1 million</b> towards Black land ownership in Othello for
                  an East African-centered Family Empower Center and Community
                  Housing.
                </span>
              </li>
              <li className="flex items-start gap-x-10">
                <img className="w-6 h-auto mt-2" src="../../imgs/liarrow.png" />
                <span>
                  <b>$400,000</b> to bring accessible and high-quality early
                  education to Petah Village in South Seattle through outdoor
                  preschool education.
                </span>
              </li>
            </ul>
          </div>

          <h1 className="text-yellow-400 text-3xl font-bold mt-10 uppercase">
            Challenges Ahead
          </h1>
          <p className="text-white text-xl font-normal mt-3">
            Despite these accomplishments, significant obstacles persist.
            Nationally and locally, there are ongoing efforts to dismantle
            racial equity initiatives. Nationally, attacks on funding
            initiatives like the Fearless Fund and reparations efforts in
            Evanston, IL, along with the denial of reparations to Tulsa Race
            Massacre survivors are alarming.
            <br />
            <br />
            Locally, attempts to divert funds from the Community Reinvestment
            Program, Participatory Budgeting, and the Equitable Development
            Initiative are concerning. Additionally, recent city planning lacks
            equitable strategies to protect vulnerable communities from
            displacement.
          </p>

          <h1 className="text-yellow-400 text-3xl font-bold mt-10 uppercase">
            Call to Action
          </h1>
          <p className="text-white text-xl font-normal mt-3">
            We urge non-Black-led organizations to refrain from competing for
            resources intended for Black communities. This perpetuates inequity
            and undermines our efforts towards justice and equality.
            <br />
            <br />
            The Nonprofit Industrial Complex has grown into a billion-dollar
            industry over 50 years, disproportionately benefitting white
            organizations while Black communities have faced ongoing decline.
            This disparity must end immediately.
            <br />
            <br />
            We also call for an end to the use of Black organizations as tokens
            by non-Black entities. Black communities are fully capable of
            directing and managing resources through our own channels.
            <br />
            <br />
            With Juneteenth now a national holiday, we call upon our supporters
            to honor its significance by recommitting to supporting our critical
            work. Together, we can dismantle systemic inequities and establish a
            new norm rooted in equity for Seattle and Martin Luther King Jr.
            County.
          </p>

          <div className="flex items-center justify-center my-10">
            <a
              href="/freedom"
              className="bg-yellow-400 text-black px-5 py-2 rounded-full text-base uppercase font-bold hover:bg-green-700 hover:text-white"
            >
              Latest freedom actions
            </a>
          </div>
        </div>
      </div>
    </Layout>
  );
}
