import React from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";

const CurrentInitiatives = ({ data, limit = null }) => {
  const blogPosts = data?.allContentfulBlogPost?.edges || [];

  return (
    <>
      {/* Header Section */}
      <div className="flex items-center justify-between mt-10">
        <h1 className="text-white text-5xl font-heading font-bold uppercase">
          Current Initiatives
        </h1>
        <Link to="/all-blog">
          <p className="uppercase text-white text-base">View All</p>
        </Link>
      </div>

      {/* Grid of Articles */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-x-5 mt-5 w-full">
        {(limit ? blogPosts.slice(0, limit) : blogPosts).map(({ node }) => (
          <Link to={`/blog/${node.slug}`} key={node.id}>
            <div className="text-center">
              <Img
                fluid={node.heroImage?.fluid}
                alt={node.title}
                className="blog-image w-full h-[120px]"
                style={{ width: "100%", height: "432px" }}
              />
              <div className="text-center p-2 mt-4">
                <p className="text-white text-center text-lg uppercase truncate">
                  {node.title}
                </p>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </>
  );
};

export { CurrentInitiatives };
