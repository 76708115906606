import { MarkdownContainer, XLHeader } from "./typography";
import { ButtonNewWindow, VariantRed, VariantGreenWhite } from "./buttons";
// import {DotsCarousel} from "./carousels";
import React, { useEffect, useState } from "react";

const LargeVideo = ({ url }) => {
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const onLoadedData = () => {
    setIsVideoLoaded(true);
  };
  return (
    <video
      autoPlay
      muted
      controls
      loop
      playsInline
      className={`focus:outline-none w-full opacity-100`}
      onLoadedData={onLoadedData}
    >
      <source
        src="https://d2j8oo2nydfmnp.cloudfront.net/sizzle-less-cropped.m4v"
        type="video/mp4"
      />
    </video>
  );
};

const LandingPageHero = ({
  mainSplashHeadline,
  mainSplashDescription,
  mainSplashCtaLink,
  mainSplashCtaButtonText,
  mainSplashCarousel,
}) => {
  const [displayContent, setDisplayContent] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setDisplayContent(true);
    }, 150);
  }, []);

  return (
    <div className="flex flex-col md:flex-row md:items-center md:min-h-3/4-screen">
      <div
        className={`w-full md:w-1/2 bg-texture border-none flex justify-center items-center py-5 pb-10`}
      >
        <div
          className={`px-8 md:px-16 ${
            displayContent ? "opacity-100" : "opacity-0"
          } transition-opacity text-white`}
        >
          <XLHeader>{mainSplashHeadline}</XLHeader>
          <MarkdownContainer className="text-white py-8">
            {mainSplashDescription.childMarkdownRemark.html}
          </MarkdownContainer>
          <div className="flex justify-start gap-x-4">
            <ButtonNewWindow to="/freedom" target={true} variant={VariantRed}>
              {mainSplashCtaButtonText}
            </ButtonNewWindow>
            <ButtonNewWindow
              to="/recentwins"
              target={true}
              variant={VariantGreenWhite}
            >
              Recent Wins
            </ButtonNewWindow>
          </div>
        </div>
      </div>

      <div
        className={`w-full md:w-1/2 ${
          displayContent ? "opacity-100" : "opacity-0"
        } transition-opacity pr-10`}
      >
        {/* <LargeVideo/> */}
        <video
          autoPlay
          muted
          controls
          loop
          className="focus:outline-none w-full h-[300px] bg-red-300"
        >
          <source src="../../sizzle-less-cropped.mp4" type="video/mp4" />
        </video>
      </div>
    </div>
  );
};

export default LandingPageHero;
