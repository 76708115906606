import React from "react";
import Layout from "../components/layout";

export default function freedom({ location }) {
  const cards = [
    {
      text: "Learn About the Occupation",
      link: "#",
      img: "../../imgs/freedom/1.png",
    },
    {
      text: "Stop WSHFC's Anti-Blackness",
      link: "#",
      img: "../../imgs/freedom/2.png",
    },
    {
      text: "Invest in Black Community Now",
      link: "#",
      img: "../../imgs/freedom/3.png",
    },
    {
      text: "Transfer 40 Acres of Black Farming",
      link: "#",
      img: "../../imgs/freedom/4.png",
    },
    {
      text: "Black Ownership at 23rd & Jackson",
      link: "#",
      img: "../../imgs/freedom/6.png",
    },
    { text: "Black Health Equity", link: "#", img: "../../imgs/freedom/1.png" },
    {
      text: "Make Pro-Black Advocacy Sustainable",
      link: "#",
      img: "../../imgs/freedom/7.png",
    },
    {
      text: "Stop Gentrification in Central District",
      link: "#",
      img: "../../imgs/freedom/2.png",
    },
    {
      text: "Stop Gentrification in Southend",
      link: "#",
      img: "../../imgs/freedom/3.png",
    },
    {
      text: "Hands Off $27 Million Black Equity in Seattle",
      link: "#",
      img: "../../imgs/freedom/5.png",
    },
    {
      text: "Budget Black Control of Black Resources",
      link: "#",
      img: "../../imgs/freedom/4.png",
    },
    {
      text: "No Gentrification on Genesee",
      link: "#",
      img: "../../imgs/freedom/7.png",
    },
  ];
  return (
    <Layout location={location}>
      <div className="container mx-auto px-8 py-8">
        <h1 className="text-center font-heading font-bold text-6xl md:text-7xl text-yellow-400 uppercase">
          <span className="text-red">Take</span> All Freedom Actions
        </h1>
        <p className="text-white text-base text-center mt-2">
          Click on any action to take that particular action.{" "}
        </p>

        {/* grid */}
        <div className="min-h-screen flex justify-center items-center pt-10">
          <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-7 w-full max-w-7xl">
            {/* Top row */}
            <Card
              text={cards[0].text}
              link={cards[0].link}
              img={cards[0].img}
            />
            <Card
              text={cards[1].text}
              link={cards[1].link}
              img={cards[1].img}
            />
            <Card
              text={cards[2].text}
              link={cards[2].link}
              img={cards[2].img}
            />
            <Card
              text={cards[3].text}
              link={cards[3].link}
              img={cards[3].img}
            />

            {/* Second row */}
            <Card
              text={cards[5].text}
              link={cards[5].link}
              img={cards[5].img}
              className="row-span-1 lg:row-span-2"
            />
            <Card
              text={cards[4].text}
              link={cards[4].link}
              img={cards[4].img}
              className="row-span-1 lg:col-span-2"
            />
            <Card
              text={cards[6].text}
              link={cards[6].link}
              img={cards[6].img}
              className="row-span-1 lg:row-span-2"
            />

            {/* Third row */}
            <Card
              text={cards[7].text}
              link={cards[7].link}
              img={cards[7].img}
            />
            <Card
              text={cards[8].text}
              link={cards[8].link}
              img={cards[8].img}
            />
            <Card
              text={cards[9].text}
              link={cards[9].link}
              img={cards[9].img}
            />
            <Card
              text={cards[10].text}
              link={cards[10].link}
              img={cards[10].img}
              className="row-span-1 lg:col-span-2"
            />

            {/* Bottom row */}
            <Card
              text={cards[11].text}
              link={cards[11].link}
              img={cards[11].img}
            />
          </div>
        </div>

        <h1 className="font-heading font-bold text-6xl md:text-7xl text-yellow-400 uppercase mt-10">
          <span className="text-red">Downloadable</span> Event Graphics
        </h1>
        <div className="flex items-center">
          <img src="../../imgs/freedom.png" className="mt-4" />
        </div>
      </div>
    </Layout>
  );
}

const Card = ({ text, link, className, img }) => (
  <a
    href={link}
    className={`group relative text-white py-5 px-10 rounded-xl border duration-300 border-white hover:border-yellow-500 hover:border-2 bg-cover bg-no-repeat bg-center ${className}`}
  >
    <span
      className="absolute rounded-xl inset-0 bg-cover w-full h-full bg-no-repeat bg-center opacity-0 group-hover:opacity-100 transition-opacity duration-300"
      style={{ backgroundImage: `url(${img})` }}
    ></span>
    <span
      className="absolute opacity-0 group-hover:opacity-100 -left-1 -top-2 bg-yellow-500 text-gray-900 rounded-full py-1 px-3 text-sm uppercase"
      style={{ rotate: "357deg" }}
    >
      Click to take this action
    </span>
    <div className="flex w-full h-full pt-4 flex-col justify-center items-center relative z-10 ">
      <span className="text-sm md:text-lg font-medium text-center uppercase">
        {text}
      </span>
      <span className="text-yellow-500 border border-yellow-500 rounded-full w-10 h-10 flex items-center justify-center mt-4 text-2xl font-bold">
        &rarr;
      </span>
    </div>
  </a>
);
