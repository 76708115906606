import React from "react";
import { Link } from "gatsby";

// Define button variants
export const VariantRed = "Red";
export const VariantWhiteOutline = "WhiteOutline";
export const VariantYellow = "Yellow";
export const VariantWhiteOutlineYellowHover = "WhiteOutlineYellowHover";
export const VariantWhiteOutlineRedHover = "WhiteOutlineRedHover";
export const VariantRedWithBorder = "RedWithBorder";
export const VariantGreenWhite = "GreenWhite";
export const VariantDarkrounded = "Darkrounded";
export const VariantDarkroundedActive = "DarkroundedActive";

const VariantClasses = {
  Red: "bg-red text-black hover:text-white",
  WhiteOutline:
    "border-2 border-white text-white hover:bg-white hover:text-black",
  Yellow: "bg-yellow text-black hover:text-white",
  WhiteOutlineYellowHover:
    "border-2 border-white text-white hover:bg-yellow hover:text-black hover:border-yellow",
  WhiteOutlineRedHover:
    "border-2 border-white text-white hover:bg-red hover:text-black hover:border-red",
  RedWithBorder: "bg-red text-black border-2 border-red",
  GreenWhite: "bg-green-600 text-white hover:bg-white hover:text-green",
  Darkrounded:
    "bg-black-900 text-white hover:bg-red-500 hover:text-white rounded-none w-fit",
  DarkroundedActive:
    "bg-red text-white hover:bg-red-500 hover:text-white rounded-none w-fit",
};

// Define default classes
const defaultClasses =
  "focus:outline-none rounded-full font-button py-2 uppercase tracking-wider";
const defaultClassesNoround =
  "focus:outline-none font-button py-2 px-5 uppercase tracking-wider";

export const Button = ({ className, children, variant, noMinWidth }) => (
  <button
    className={`${defaultClasses} ${noMinWidth ? "" : "min-w-button"} ${
      VariantClasses[variant]
    } ${className}`}
  >
    {children}
  </button>
);

export const SubmitButton = ({ className, children }) => (
  <button
    type="submit"
    className={`${defaultClasses} min-w-button ${className} ${VariantClasses[VariantWhiteOutline]}`}
  >
    {children}
  </button>
);

export const ButtonOnClick = ({ onClick, className, children, variant }) => (
  <button
    onClick={onClick}
    className={`min-w-button ${defaultClasses} ${VariantClasses[variant]} ${className}`}
  >
    {children}
  </button>
);

// Button for New Window Links
export const ButtonNewWindow = ({
  to,
  className,
  children,
  variant,
  target = null,
}) => (
  <a href={to} target={!target ? "_blank" : ""} rel="noreferrer">
    <button
      className={`min-w-button ${defaultClasses} ${VariantClasses[variant]} ${className}`}
    >
      {children}
    </button>
  </a>
);

// Button without Rounded Corners
export const ButtonNoRoundWindow = ({
  to = null,
  className,
  children,
  variant,
  target = null,
  onclick = null,
}) => (
  <a href={to} target={!target ? "_blank" : ""} rel="noreferrer">
    <button
      onClick={onclick}
      className={`w-fit ${defaultClassesNoround} ${VariantClasses[variant]} ${className}`}
    >
      {children}
    </button>
  </a>
);

export const DonateButton = ({ className, text, variant }) => (
  <button
    data-campaign-id="4028"
    data-custom="true"
    className={`commitchange-donate ${defaultClasses} text-black ${className} ${
      variant && VariantClasses[variant]
    }`}
  >
    {text || "donate"}
  </button>
);

export const RichTextButtonRow = ({ links }) => (
  <div className="w-full flex justify-center px-4 pb-4">
    {links.map((link, index) => {
      switch (link.linkType) {
        case "External Link":
          return (
            <ButtonNewWindow
              key={index}
              variant={VariantRed}
              className="mx-4 text-base px-2"
              to={link.linkUrl}
            >
              {link.linkNameOverride || link.headerNavLinkName}
            </ButtonNewWindow>
          );
        case "Internal Page":
          return (
            <Link key={index} to={link.linkUrl}>
              <Button variant={VariantRed} className="mx-4 text-base px-2">
                {link.linkNameOverride || link.headerNavLinkName}
              </Button>
            </Link>
          );
        case "Donate Button":
          return (
            <DonateButton
              className="my-4 md:my-0 text-base px-2"
              text={link.headerNavLinkName}
              variant={VariantRed}
            />
          );
        default:
          console.error("UNSUPPORTED BUTTON TYPE", link.linkType);
          return <></>;
      }
    })}
  </div>
);

export const VendorButton = ({ className, text }) => (
  <button
    data-campaign-id="4028"
    data-custom="true"
    data-designation="Juneteenth Vendors"
    data-single-amount="20"
    data-type="one-time"
    className={`commitchange-donate ${defaultClasses} text-black ${className}`}
  >
    {text || "donate"}
  </button>
);
