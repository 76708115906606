import React, { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, A11y, Autoplay, Navigation } from "swiper";
import "./new.css";
import Img from "gatsby-image";
import { Link } from "gatsby";

// Initialize Swiper modules
SwiperCore.use([Pagination, A11y, Autoplay, Navigation]);

const BlogSlider = ({ data }) => {
  const blogPosts = data?.allContentfulBlogPost?.edges || [];

  return (
    <div className="trendingInit">
      <Swiper
        modules={[Pagination, A11y, Autoplay, Navigation]}
        spaceBetween={16}
        slidesPerView={1}
        autoplay={{ delay: 3000, disableOnInteraction: false }}
        navigation
        pagination={false}
        loop={true}
        speed={600}
        breakpoints={{
          640: {
            slidesPerView: 1,
            spaceBetween: 16,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 16,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 16,
          },
        }}
      >
        {blogPosts.slice(0, 5).map(({ node }) => (
          <SwiperSlide key={node.id}>
            <Link to={`/blog/${node.slug}`}>
              <div className="blog-slide">
                <Img
                  fluid={node.heroImage?.fluid}
                  alt={node.title}
                  className="blog-image w-full h-[250px]"
                  style={{ width: "100%", height: "432px" }}
                />
                <div className="text-center p-2 mt-4">
                  <p className="text-white text-center text-lg uppercase truncate">
                    {node.title}
                  </p>
                  <p className="text-white text-center text-base font-light mt-2">
                    {node.slug}
                  </p>
                </div>
              </div>
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export { BlogSlider };
