import React from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";
import "./new.css";

const News = ({ data, limit = null }) => {
  const blogPosts = data?.allContentfulBlogPost?.edges || [];

  console.log(blogPosts);

  return (
    <>
      {/* Header Section */}
      <div className="flex items-center justify-between mt-10">
        <h1 className="text-white text-5xl font-heading font-bold uppercase">
          News & Publications
        </h1>
        <Link to="/all-blog">
          <p className="uppercase text-white text-base">View All</p>
        </Link>
      </div>

      {/* Grid of Articles */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-5 mt-5 w-full">
        {(limit ? blogPosts.slice(0, limit) : blogPosts).map(
          ({ node }, index) =>
            // Use correct JSX conditional wrapping
            index === 0 ? (
              <div className="col-spa-1 md:col-span-2">
                <Link to={`/blog/${node.slug}`} key={node.id}>
                  <div className="flex flex-col md:flex-row mb-3 lg:mb-0 items-start justify-between border overflow-hidden border-gray-100">
                    <div className="text-left p-5 mt-4 w-full md:w-[60%]">
                      <p className="text-white text-xl uppercase font-semibold border-b border-gray-100 pb-3">
                        {node.title}
                      </p>
                      <div
                        dangerouslySetInnerHTML={{
                          __html:
                            node.description?.childMarkdownRemark?.html || "",
                        }}
                        className="text-white text-base mt-2"
                      />
                      <a
                        href={`/blog/${node.slug}`}
                        className="text-yellow-400 underline decoration-yellow-400 mt-2"
                      >
                        Read more
                      </a>
                    </div>
                    <div className="w-full md:w-[40%] h-[100%]">
                      <Img
                        fluid={node.heroImage?.fluid}
                        className="w-full"
                        alt={node.title || "Blog Image"}
                        style={{ width: "100%", height: "350px" }}
                      />
                    </div>
                  </div>
                </Link>
              </div>
            ) : (
              <div>
                <Link to={`/blog/${node.slug}`} key={node.id}>
                  <div className="text-center">
                    {/* Image Section */}
                    <Img
                      fluid={node.heroImage?.fluid}
                      className="w-full h-[300px] object-cover"
                      alt={node.title || "Blog Image"}
                      style={{ width: "100%", height: "300px" }}
                    />

                    {/* Title and Category Section */}
                    <div className="text-center p-2 mt-4">
                      <p className="text-white text-lg uppercase truncate">
                        {node.title}
                      </p>
                    </div>
                  </div>
                </Link>
              </div>
            )
        )}
      </div>
    </>
  );
};

export { News };
