import React from "react";
import { graphql } from "gatsby";
import get from "lodash/get";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";
import CampaignTicker from "../components/landing-page-sections/campaign-ticker";
import {
  HeadlineBlurbCTASection,
  HeadlineBlurbSection,
} from "../components/sections";
// import HoverSolutionsGrid from "../components/landing-page-sections/hover-solutions-grid";
import SplitSectionBlogSlider from "../components/landing-page-sections/split-section-blog-slider";
import FeaturedSectionWithVideo from "../components/landing-page-sections/featured-section-video";
import LandingPageHero from "../components/landing-hero";
import { ArrowsCarousel } from "../components/carousels";
import ArticlePreview from "../components/article-preview";
import ScrollAnchor from "../components/scroll-anchor";
import { IGPosts } from "../components/landing-page-sections/embedded-ig";
import { HoverColorVariant } from "../utils/get-hover-classes";

import {
  VariantDarkrounded,
  ButtonNoRoundWindow,
  VariantDarkroundedActive,
} from "../components/buttons";
import { LGHeader, XSHeader } from "../components/typography";

class RootIndex extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: 1,
      selectedCategory: null,
    };
  }

  setCurrentTab = (tab, cat) => {
    this.setState({ currentTab: tab });
    this.setState({ selectedCategory: cat });
  };

  state = {
    loaded: false,
  };

  componentDidMount() {
    this.setState({ loaded: true });
  }

  render() {
    const siteTitle = get(this, "props.data.site.siteMetadata.title");
    const landingPage = get(this, "props.data.contentfulLandingPage");

    return (
      <Layout location={this.props.location}>
        <div>
          <Helmet>
            <title>{siteTitle}</title>
          </Helmet>
          <LandingPageHero
            mainSplashHeadline={landingPage.mainSplashHeadline}
            mainSplashDescription={landingPage.mainSplashDescription}
            mainSplashCtaLink={landingPage.mainSplashCtaLink}
            mainSplashCtaButtonText={landingPage.mainSplashCtaButtonText}
            mainSplashCarousel={landingPage.mainSplashCarousel}
          />
        </div>
        <CampaignTicker
          campaignTickerLink={landingPage.marqueeTickerLink}
          campaignTickerText={landingPage.marqueeTickerText}
        />
        <HeadlineBlurbSection
          {...landingPage.firstBannerSectionHeadlineDescription}
          className="bg-green text-white"
        />
        {/*<HoverSolutionsGrid blockData={landingPage.equitySolutions}/>*/}
        <ScrollAnchor id="blog-slider" />
        <section>
          <div className="flex w-full px-8 md:px-12 mt-8 flex-col md:flex-row items-start md:items-center justify-center md:justify-start gap-x-2">
            <XSHeader className="mr-5 text-white mt-4">Recent Actions</XSHeader>
            <div className="flex flex-wrap items-center gap-2 md:gap-x-2">
              <ButtonNoRoundWindow
                onclick={() => this.setCurrentTab(1, null)}
                variant={
                  this.state.currentTab === 1
                    ? VariantDarkroundedActive
                    : VariantDarkrounded
                }
              >
                Recent wins
              </ButtonNoRoundWindow>
              <ButtonNoRoundWindow
                onclick={() => this.setCurrentTab(2, "News")}
                variant={
                  this.state.currentTab === 2
                    ? VariantDarkroundedActive
                    : VariantDarkrounded
                }
              >
                Freedom actions
              </ButtonNoRoundWindow>
              <ButtonNoRoundWindow
                onclick={() => this.setCurrentTab(3, "Initiatives")}
                variant={
                  this.state.currentTab === 3
                    ? VariantDarkroundedActive
                    : VariantDarkrounded
                }
              >
                initiatives
              </ButtonNoRoundWindow>
            </div>
          </div>
          <ArrowsCarousel headline={landingPage.blogCarouselHeader}>
            {this.state.selectedCategory === "News"
              ? landingPage.recentUpdatesArticles
                  .slice(0, 5)
                  .map((post, index) => (
                    <ArticlePreview key={index} article={post} />
                  ))
              : this.state.selectedCategory === "Initiatives"
              ? landingPage.recentUpdatesArticles
                  .slice(0, 5)
                  .reverse()
                  .map((post, index) => (
                    <ArticlePreview key={index} article={post} />
                  ))
              : landingPage.recentUpdatesArticles
                  .slice(0, 5)
                  .reverse()
                  .map((post, index) => (
                    <ArticlePreview key={index} article={post} />
                  ))}
          </ArrowsCarousel>
        </section>
        <ScrollAnchor id="equity-solutions" />
        <HeadlineBlurbCTASection
          {...landingPage.payTheFeeSection}
          className="bg-red text-white"
          useDonateButton
        />
        <SplitSectionBlogSlider
          {...landingPage.whatWeDoSection}
          hoverColor={HoverColorVariant.Yellow}
        />
        {/* <SplitSectionBlogSlider {...landingPage.whereWereGoingSection} hoverColor={HoverColorVariant.Green}/> */}
        <FeaturedSectionWithVideo
          {...landingPage.featuredHeadlineVideoSection}
        />
        <HeadlineBlurbCTASection
          {...landingPage.volunteerSection}
          className="bg-green text-white"
        />
        {/*<IGPosts featuredInstagramPosts={landingPage.featuredInstagramPosts}/>*/}
      </Layout>
    );
  }
}

export default RootIndex;

export const pageQuery = graphql`
  query HomeQuery {
    site {
      siteMetadata {
        title
      }
    }
    contentfulLandingPage(contentful_id: { eq: "6vXPKQ0ozVXRBvNTt0GJZn" }) {
      mainSplashHeadline
      mainSplashDescription {
        childMarkdownRemark {
          html
        }
      }
      mainSplashCtaLink
      mainSplashCtaButtonText
      mainSplashCarousel {
        description
        file {
          url
          contentType
        }
      }
      marqueeTickerText
      marqueeTickerLink
      firstBannerSectionHeadlineDescription {
        headline
        blurb {
          childMarkdownRemark {
            html
          }
        }
      }
      blogCarouselHeader
      recentUpdatesArticles {
        ... on ContentfulBlogPost {
          title
          slug
          heroImage {
            fluid(maxWidth: 600, maxHeight: 336, resizingBehavior: FILL) {
              ...GatsbyContentfulFluid
            }
          }
        }
        ... on ContentfulArticle {
          title
          url
          heroImage {
            fluid(maxWidth: 600, maxHeight: 336, resizingBehavior: FILL) {
              ...GatsbyContentfulFluid
            }
          }
        }
        ... on ContentfulCustomLinkTree {
          title
          slug
          overrideUrl
          heroImage {
            fluid(maxWidth: 600, maxHeight: 336, resizingBehavior: FILL) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
      whatWeDoSection {
        headline
        description {
          childMarkdownRemark {
            html
          }
        }
        featuredBlogPosts {
          ... on ContentfulBlogPost {
            title
            slug
            heroImage {
              fluid(maxWidth: 700, maxHeight: 396, resizingBehavior: FILL) {
                ...GatsbyContentfulFluid
              }
            }
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
            description {
              childMarkdownRemark {
                html
              }
            }
          }
          ... on ContentfulSolutionPage {
            title
            slug
            heroImage {
              fluid(maxWidth: 700, maxHeight: 396, resizingBehavior: FILL) {
                ...GatsbyContentfulFluid
              }
            }
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
            description {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
      payTheFeeSection {
        headline
        blurb {
          childMarkdownRemark {
            html
          }
        }
        ctaButtonText
        ctaButtonLink
      }
      whereWereGoingSection {
        headline
        description {
          childMarkdownRemark {
            html
          }
        }
        featuredBlogPosts {
          ... on ContentfulBlogPost {
            title
            slug
            heroImage {
              fluid(maxWidth: 700, maxHeight: 396, resizingBehavior: FILL) {
                ...GatsbyContentfulFluid
              }
            }
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
            description {
              childMarkdownRemark {
                html
              }
            }
          }
          ... on ContentfulSolutionPage {
            title
            slug
            heroImage {
              fluid(maxWidth: 700, maxHeight: 396, resizingBehavior: FILL) {
                ...GatsbyContentfulFluid
              }
            }
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
            description {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
      featuredHeadlineVideoSection {
        headlineSection {
          headline
          blurb {
            childMarkdownRemark {
              html
            }
          }
        }
        youTubeLink
      }
      volunteerSection {
        headline
        blurb {
          childMarkdownRemark {
            html
          }
        }
        ctaButtonText
        ctaButtonLink
      }
      featuredInstagramPosts
    }
  }
`;
