import React from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";

const BlogSection = ({ data, limit = null }) => {
  const blogPosts = data?.allContentfulBlogPost?.edges || [];

  return (
    <div>
      {/* Header Section */}
      <div className="flex items-center justify-between mt-10">
        <h1 className="text-white text-5xl font-heading font-bold uppercase">
          Blog
        </h1>
        <Link to="/all-blog">
          <p className="uppercase text-white text-base">View All</p>
        </Link>
      </div>

      {limit ? (
        <div className="flex flex-col md:flex-row lg:flex-row gap-5 mt-5">
          <div className="flex flex-col gap-5 w-full md:w-1/2">
            {blogPosts.slice(0, 2).map(({ node }) => {
              const imageUrl = node?.heroImage?.fluid; // Fallback to a default image
              return (
                <Link to={`/blog/${node.slug}`} key={node.id}>
                  <div className="text-center">
                    {/* Image Section */}
                    <Img
                      fluid={node.heroImage?.fluid}
                      className="w-full h-52 object-cover h-[200px]"
                      alt={node.title || "Blog Image"}
                      style={{ width: "100%", height: "250px" }}
                    />

                    {/* Title and Category Section */}
                    <div className="text-center p-2 mt-4">
                      <p className="text-white text-lg uppercase truncate">
                        {node.title}
                      </p>
                    </div>
                  </div>
                </Link>
              );
            })}
          </div>

          <div className="w-full md:w-1/">
            {blogPosts[2] && (
              <Link to={`/blog/${blogPosts[2].node.slug}`}>
                <div className="relative bg-gray-900 rounded-lg overflow-hidden h-full">
                  <Img
                    fluid={blogPosts[2].node?.heroImage?.fluid}
                    alt={blogPosts[2]?.node?.title || "Featured Blog"}
                    className="w-full h-[200px] object-cover opacity-80"
                    style={{ width: "100%", height: "100%" }}
                  />
                  <div className="absolute bottom-3 left-3 flex flex-col justify-center items-start text-center p-6">
                    <p className="text-white text-xl font-bold uppercase mb-4">
                      {blogPosts[2]?.node?.title}
                    </p>
                    <Link
                      to={`/blog/${blogPosts[2]?.node?.slug}`}
                      className="bg-yellow-500 text-black px-4 py-2 rounded uppercase font-semibold"
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </Link>
            )}
          </div>
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-3 gap-x-5 mt-5">
          {blogPosts.map(({ node }) => (
            <Link to={`/blog/${node.slug}`} key={node.id}>
              <div className="text-center">
                {/* Image Section */}
                <Img
                  fluid={node.heroImage?.fluid}
                  className="w-full h-[200px] object-cover"
                  alt={node.title || "Blog Image"}
                  style={{ width: "100%", height: "250px" }}
                />

                {/* Title and Category Section */}
                <div className="text-center p-2 mt-4">
                  <p className="text-white text-lg uppercase truncate">
                    {node.title}
                  </p>
                </div>
              </div>
            </Link>
          ))}
        </div>
      )}
    </div>
  );
};

export { BlogSection };
