// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-all-blog-js": () => import("./../../../src/pages/all-blog.js" /* webpackChunkName: "component---src-pages-all-blog-js" */),
  "component---src-pages-freedom-js": () => import("./../../../src/pages/freedom.js" /* webpackChunkName: "component---src-pages-freedom-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inittives-js": () => import("./../../../src/pages/inittives.js" /* webpackChunkName: "component---src-pages-inittives-js" */),
  "component---src-pages-recentwins-js": () => import("./../../../src/pages/recentwins.js" /* webpackChunkName: "component---src-pages-recentwins-js" */),
  "component---src-pages-solutions-js": () => import("./../../../src/pages/solutions.js" /* webpackChunkName: "component---src-pages-solutions-js" */),
  "component---src-pages-work-js": () => import("./../../../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-election-candidate-page-js": () => import("./../../../src/templates/election-candidate-page.js" /* webpackChunkName: "component---src-templates-election-candidate-page-js" */),
  "component---src-templates-election-landing-page-js": () => import("./../../../src/templates/election-landing-page.js" /* webpackChunkName: "component---src-templates-election-landing-page-js" */),
  "component---src-templates-link-page-js": () => import("./../../../src/templates/link-page.js" /* webpackChunkName: "component---src-templates-link-page-js" */),
  "component---src-templates-solution-page-js": () => import("./../../../src/templates/solution-page.js" /* webpackChunkName: "component---src-templates-solution-page-js" */)
}

