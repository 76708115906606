import React, { useState } from "react";
import Layout from "../components/layout";
import { BlogSlider } from "../components/new/trendingblog";
import { Recentwins } from "../components/new/recentwins";
import { CurrentInitiatives } from "../components/new/curent";
import { BlogSection } from "../components/new/blog";
import { News } from "../components/new/news";
import { graphql } from "gatsby";
import { DonateButton } from "../components/buttons";


export default function Initiatives({ data }) {
  const [activeTab, setActiveTab] = useState(1);
  const tabs = [
    { id: 1, label: "All" },
    { id: 2, label: "Recent Wins" },
    { id: 3, label: "News & Publications" },
    { id: 4, label: "Current Initiatives" },
    { id: 5, label: "Blogs" },
  ];
  return (
    <Layout location={data}>
      <div className="container mx-auto px-8">
        {/* Hero section */}
        <div className="flex flex-col-reverse md:flex-row items-center justify-between py-8">
          <div className="w-full text-center md:text-left md:w-1/2 pr-10">
            <h1 className="font-heading text-center md:text-left text-white font-bold text-5xl md:text-8xl uppercase">
              Making a difference through one initiative at a time.
            </h1>
            <p className="text-center md:text-left text-white text-xl mt-2">
              We are Black-led, Black-centered, and birthed out of a deep legacy
              of Black organizing in Martin Luther King Jr. County, Washington
              State.
            </p>
            <DonateButton
              className="bg-yellow px-6 mb-4 md:mb-0 mt-5"
              text="Make a Donation"
            />
          </div>
          <div className="w-full h-full md:w-1/2">
            <img src="../../imgs/initives.png" className="w-full h-full" />
          </div>
        </div>

        {/* trending blog slider */}
        <div className="flex flex-col mt-10">
          <h1 className="text-white text-5xl font-heading font-bold uppercase">
            Trending
          </h1>
          <div className="mt-5">
            <BlogSlider data={data} />
          </div>
        </div>

        {/* tab setions */}
        <div className="py-10">
          <div className="w-full">
            <div className="flex items-center justify-between flex-wrap md:flex-nowrap">
              {tabs.map((tab) => (
                <button
                  key={tab.id}
                  onClick={() => setActiveTab(tab.id)}
                  className={`w-full px-2 py-3 md:py-5 text-white outline-none border-none shadow-none text-base font-button font-bold hover:bg-red uppercase ${
                    activeTab === tab.id ? "bg-red" : " bg-blue-500"
                  }`}
                >
                  {tab.label}
                </button>
              ))}
            </div>

            {/* Tab Content */}
            <div>
              {(activeTab === 1 || activeTab == 2) && (
                <Recentwins data={data} limit={activeTab === 1 ? 3 : 6} />
              )}

              {(activeTab === 1 || activeTab == 3) && (
                <News data={data} limit={activeTab === 1 ? 5 : 5} />
              )}

              {(activeTab === 1 || activeTab == 4) && (
                <CurrentInitiatives
                  data={data}
                  limit={activeTab === 1 ? 3 : 6}
                />
              )}

              {(activeTab === 1 || activeTab == 5) && (
                <BlogSection data={data} limit={activeTab === 1 ? 3 : 6} />
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  {
    contentfulBlogsPage(contentful_id: { eq: "Od5hDeljrmFmi0OXgoaXu" }) {
      blogArchiveTitle
    }
    allContentfulBlogPost(
      filter: { slug: { ne: "richtext-entry-not-visible-do-not-delete" } }
    ) {
      edges {
        node {
          updatedAt
          sys {
            contentType {
              sys {
                id
              }
            }
          }
          title
          slug
          heroImage {
            fluid(maxWidth: 600, maxHeight: 336, resizingBehavior: FILL) {
              ...GatsbyContentfulFluid
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`;
